<template>
  <div
    style="position: relative; max-height: 100%"
    id="scroll-target"
    class="overflow-y-auto"
  >
    <v-slide-y-transition>
      <v-btn
        z-index="1000"
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        small
        color="primary"
        style="position: fixed; bottom: 20px; right: 20px; z-index: 999"
        @click="$vuetify.goTo(0)"
      >
        <v-icon>mdi-arrow-up-bold-circle</v-icon>
      </v-btn>
    </v-slide-y-transition>

    <v-container v-scroll:#scroll-target="onScroll">
      <div class="ma-5 text-center">
        <div class="headline">
          {{ $t("app.registration") }}
          {{
            guruOrStaff ? $t("ppdb.teacher.tab1") : $t("ppdb.teacher.switch")
          }}
        </div>
        <v-layout class="mt-3" justify-center>
          <v-flex md2 sm2 xs2>
            <ToggleButton
              v-model="guruOrStaff"
              :labels="{
                checked: $t('ppdb.teacher.tab1'),
                unchecked: $t('ppdb.teacher.switch')
              }"
              :color="{ checked: '#3e4295', unchecked: '#27ACE2' }"
              :width="120"
              :height="40"
              id="changed-font"
              @change="switchGuruOrStaff"
            />
          </v-flex>
        </v-layout>
      </div>
      <v-stepper v-model="stepPosition">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepPosition > 1"
            step="1"
            :rules="[() => g_ppdb_guru.completed]"
            >{{
              guruOrStaff ? $t("ppdb.teacher.tab1") : $t("ppdb.teacher.switch")
            }}</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step
            :complete="stepPosition > 2"
            step="2"
            :rules="[() => g_ppdb_riwayat.completed]"
            >{{ $t("ppdb.teacher.tab2") }}</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="guru" lazy-validation>
              <div class="mb-3">
                <Teachers :is-valid="formValidation" />
              </div>
              <div class="mb-5 mr-2 text-right">
                <v-btn
                  dark
                  color="gradient-primary-dark"
                  @click="nextStep(1, 'guru')"
                >
                  {{ $t("app.next") }}
                </v-btn>
              </div>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-form ref="riwayat" lazy-validation>
              <div class="mb-5">
                <EducationalHistory :is-valid="formValidation" />
                <v-alert
                  v-if="error"
                  class="amber darken-2"
                  border="left"
                  dense
                  dark
                  dismissible
                >
                  {{ errorMsg }}
                </v-alert>
              </div>
              <div class="mb-5 mr-2 text-right">
                <v-btn text @click="stepPosition = 1">{{
                  $t("app.back")
                }}</v-btn>
                <v-btn
                  dark
                  color="gradient-primary-dark"
                  @click="savePendaftaranGuru('riwayat')"
                >
                  {{ $t("app.save") }}
                </v-btn>
              </div>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <RegisterDone
        :modal-register-done="modalRegisterDone"
        :modal-register-done-handler="closeModalRegisterDone"
        :username="dataDone.username"
        :password="dataDone.password"
        :email="dataDone.email"
        :person="dataDone.person"
        type="teacher"
      />
    </v-container>
  </div>
</template>

<script>
import { Teachers, EducationalHistory, RegisterDone } from "./components";
import { mapGetters } from "vuex";
import { isFormValid } from "@/utils/validate";
import store from "@/store";
import i18n from "@/i18n";
import { insert_ppdb_guru } from "@/api/homepage/ppdb";

export default {
  name: "PpdbGuru",
  computed: mapGetters([
    "g_ppdb_guru",
    "g_ppdb_riwayat",
    "g_ppdbguru",
    "g_master_data",
    "g_language"
  ]),
  metaInfo: {
    title: i18n.t("routes.teacher_registration"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Teachers,
    EducationalHistory,
    RegisterDone
  },
  data() {
    return {
      fab: false,
      stepPosition: 1,
      steps: 2,
      modalRegisterDone: false,
      guruOrStaff: true,
      dataDone: {
        username: "",
        password: "",
        email: [],
        person: 0
      },
      errorMsg: "",
      error: false
    };
  },
  watch: {
    steps(val) {
      if (this.stepPosition > val) {
        this.stepPosition = val;
      }
    }
  },
  created: function() {
    store.dispatch("getMasterData");
    this.$store.commit("CALL_SNACKBAR", {
      msg: this.$i18n.t("ppdb.teacher.info_switch"),
      color: "warning"
    });
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 150;
    },
    formValidation(text, type) {
      return isFormValid(text, type);
    },
    closeModalRegisterDone() {
      this.modalRegisterDone = false;
    },
    onInput(val) {
      this.steps = parseInt(val);
    },
    savePendaftaranGuru(tab) {
      store.commit("SET_PANEL_GURU", { tab: "riwayat", val: [0, 1, 2, 3, 4] });
      this.errorMsg = "";
      this.error = false;
      const dataPpdb = { ...this.g_ppdbguru };

      if (this.$refs[tab].validate()) {
        dataPpdb.riwayat.pengalaman_kerja.map(d =>
          d.tahun_masuk.length < 10
            ? (d.tahun_masuk = d.tahun_masuk.concat("-01"))
            : d.tahun_masuk
        );
        dataPpdb.riwayat.pengalaman_kerja.map(d =>
          d.tahun_keluar
            ? (d.tahun_keluar = d.tahun_keluar.concat("-01"))
            : d.tahun_keluar
        );

        store.commit("SET_COMPLETE_TAB_GURU", {
          status: true,
          param: tab
        });
        store.commit("TOGGLE_FULL_LOADING");
        insert_ppdb_guru(dataPpdb)
          .then(res => {
            const r = res.data;
            if (r.code) {
              store.commit("TOGGLE_FULL_LOADING");
              this.dataDone.username = r.data.username;
              this.dataDone.password = r.data.password;
              this.dataDone.email = r.data.email_sended;
              this.dataDone.person = r.data.person;
              this.modalRegisterDone = true;
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.success_save"),
                color: "success"
              });
            } else {
              store.commit("TOGGLE_FULL_LOADING");
              this.errorMsg = r.message;
              this.error = true;
            }
          })
          .catch(err => {
            store.commit("TOGGLE_FULL_LOADING");
            this.$store.commit("CALL_SNACKBAR", {
              msg: err,
              color: "error"
            });
          });
      } else {
        store.commit("SET_COMPLETE_TAB_GURU", {
          status: false,
          param: tab
        });
        this.$nextTick(() => {
          const element = document.querySelector(".v-input.error--text");
          if (element) this.$vuetify.goTo(element);
        });
      }
    },
    nextStep(n, tab) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        switch (tab) {
          case "guru":
            store.commit("SET_PANEL_GURU", { tab: "guru", val: [0, 1] });
            break;
        }

        setTimeout(() => {
          if (this.$refs[tab].validate()) {
            this.stepPosition = n + 1;
            store.commit("SET_COMPLETE_TAB_GURU", {
              status: true,
              param: tab
            });
          } else {
            store.commit("SET_COMPLETE_TAB_GURU", {
              status: false,
              param: tab
            });
            this.$vuetify.goTo(140);
          }
        }, 200);
      }
      this.$vuetify.goTo(140);
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    },
    switchGuruOrStaff() {
      store.commit("SWITCH_GURU_OR_STAFF", this.guruOrStaff);
      this.snackBar(this.guruOrStaff);
    },
    snackBar(isTeacher) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: isTeacher
          ? this.$i18n.t("ppdb.teacher.snackbar_msg_teacher")
          : this.$i18n.t("ppdb.teacher.snackbar_msg_staff"),
        color: isTeacher ? "#3e4295" : "#27ACE2"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vue-js-switch#changed-font {
  font-size: 16px !important;
}
</style>
